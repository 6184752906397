define("@ember-decorators/object/index", ["exports", "@ember-decorators/utils/decorator"], function (_exports, _decorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.off = _exports.on = _exports.unobserves = _exports.observes = void 0;

  function _createForOfIteratorHelper(o) { if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (o = _unsupportedIterableToArray(o))) { var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var it, normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  /**
    Triggers the target function when the dependent properties have changed. Note,
    `@observes` _must_ be used on EmberObject based classes only, otherwise there
    may be subtle issues and breakage.
  
    ```javascript
    import { observes } from '@ember-decorators/object';
  
    class Foo {
      @observes('foo')
      bar() {
        //...
      }
    }
    ```
  
    @function
    @param {...String} propertyNames - Names of the properties that trigger the function
   */
  var observes = (0, _decorator.decoratorWithRequiredParams)(function (target, key, desc, params) {
    (false && !(desc && typeof desc.value === 'function') && Ember.assert('The @observes decorator must be applied to functions', desc && typeof desc.value === 'function'));
    (false && !(target instanceof Ember.Object) && Ember.assert("You attempted to use @observes on ".concat(target.constructor.name, "#").concat(key, ", which does not extend from EmberObject. Unfortunately this does not work with stage 1 decorator transforms, and will break in subtle ways. You must rewrite your class to extend from EmberObject."), target instanceof Ember.Object));

    var _iterator = _createForOfIteratorHelper(params),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var path = _step.value;
        Ember.expandProperties(path, function (expandedPath) {
          Ember.addObserver(target, expandedPath, null, key);
        });
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return desc;
  }, 'observes');
  /**
    Removes observers from the target function.
  
    ```javascript
    import { observes, unobserves } from '@ember-decorators/object';
  
    class Foo {
      @observes('foo')
      bar() {
        //...
      }
    }
  
    class Bar extends Foo {
      @unobserves('foo') bar;
    }
    ```
  
    @function
    @param {...String} propertyNames - Names of the properties that no longer trigger the function
   */

  _exports.observes = observes;
  var unobserves = (0, _decorator.decoratorWithRequiredParams)(function (target, key, desc, params) {
    var _iterator2 = _createForOfIteratorHelper(params),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var path = _step2.value;
        Ember.expandProperties(path, function (expandedPath) {
          Ember.removeObserver(target, expandedPath, null, key);
        });
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }

    return desc;
  }, 'unobserves');
  /**
    Adds an event listener to the target function.
  
    ```javascript
    import { on } from '@ember-decorators/object';
  
    class Foo {
      @on('fooEvent', 'barEvent')
      bar() {
        //...
      }
    }
    ```
  
    @function
    @param {...String} eventNames - Names of the events that trigger the function
   */

  _exports.unobserves = unobserves;
  var on = (0, _decorator.decoratorWithRequiredParams)(function (target, key, desc, params) {
    (false && !(desc && typeof desc.value === 'function') && Ember.assert('The @on decorator must be applied to functions', desc && typeof desc.value === 'function'));

    var _iterator3 = _createForOfIteratorHelper(params),
        _step3;

    try {
      for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
        var eventName = _step3.value;
        Ember.addListener(target, eventName, null, key);
      }
    } catch (err) {
      _iterator3.e(err);
    } finally {
      _iterator3.f();
    }

    return desc;
  }, 'on');
  /**
    Removes an event listener from the target function.
  
    ```javascript
    import { on, off } from '@ember-decorators/object';
  
    class Foo {
      @on('fooEvent', 'barEvent')
      bar() {
        //...
      }
    }
  
    class Bar extends Foo {
      @off('fooEvent', 'barEvent') bar;
    }
    ```
  
    @function
    @param {...String} eventNames - Names of the events that no longer trigger the function
   */

  _exports.on = on;
  var off = (0, _decorator.decoratorWithRequiredParams)(function (target, key, desc, params) {
    var _iterator4 = _createForOfIteratorHelper(params),
        _step4;

    try {
      for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
        var eventName = _step4.value;
        Ember.removeListener(target, eventName, null, key);
      }
    } catch (err) {
      _iterator4.e(err);
    } finally {
      _iterator4.f();
    }

    return desc;
  }, 'off');
  _exports.off = off;
});